<template>
  <v-container fluid class="px-0 pt-0">
    <v-row>
      <v-col class="col-12 pt-0">
        <div>
          <map-box
            :displayDrawControlsDefault="false"
            :drawControl="false"
            :drawControls="controls"
            :geolocateControl="false"
            :mapConfiguration="mapConfig"
            :navigationControl="false"
            :oneLocation="true"
            :scaleControl="false"
            :searchControl="false"
            ref="mapLocations"
            :confStyle="'satellite-v9'"
            v-on:loadComplete="initialize"
            @openSheetTracking="openSheetTrack"
            :mapHeight="70"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapBox from "@/components/vueMapbox/MapBox.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Map",
  components: {
    MapBox,
  },
  data: () => ({
    line: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            properties: {},
            coordinates: [],
          },
        },
      ],
    },

    // draw controls
    controls: {},
  }),
  created() {
    this.final();
  },
  computed: {
    recordSelected: {
      get() {
        return this.$store.getters["final/recordTrackindData"];
      },
      set(val) {
        this.$store.commit("final/SET_RECORD_TRACKING_DATA", val);
      },
    },
    ...mapGetters({
      mapConfig: "general/mapConfigFinal",
    }),
  },
  methods: {
    ...mapActions({
      final: "final/final",
    }),
    initialize() {
      let allFeatures = {
        type: "FeatureCollection",
        features: [],
      };
      let location1 = null;
      let location2 = null;
      if (
        this.recordSelected.record.sdi_origin.location !== "null" &&
        this.recordSelected.record.sdi_destination.location !== "null" &&
        this.IsJsonString(
          this.recordSelected.record.sdi_destination.location
        ) &&
        this.IsJsonString(this.recordSelected.record.sdi_origin.location)
      ) {
        location1 = JSON.parse(
          this.recordSelected.record.sdi_origin.location
        );
        location2 = JSON.parse(
          this.recordSelected.record.sdi_destination.location
        );
        if (location1.features[0].geometry.type === "Point") {
          location1 = location1.features[0];
          this.line.features[0].geometry.coordinates.push(
            location1.geometry.coordinates
          );
        } else {
          const center = this.$refs.mapLocations.getCenter(location1);
          location1 = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: center,
            },
            properties: {},
          };
          this.line.features[0].geometry.coordinates.push(center);
        }
        if (location2.features[0].geometry.type === "Point") {
          location2 = location2.features[0];
          this.line.features[0].geometry.coordinates.push(
            location2.geometry.coordinates
          );
        } else {
          const center = this.$refs.mapLocations.getCenter(location2);
          location2 = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: center,
            },
            properties: {},
          };
          this.line.features[0].geometry.coordinates.push(center);
        }
        location1.properties.name =
          this.recordSelected.record.sdi_origin.primaryLocation.name;
        location1.properties.address1 =
          this.recordSelected.record.sdi_origin.primaryLocation.address1;
        location1.properties.city =
          this.recordSelected.record.sdi_origin.primaryLocation.city;
        location1.properties.state =
          this.recordSelected.record.sdi_origin.primaryLocation.state;
        location1.properties.country =
          this.recordSelected.record.sdi_origin.primaryLocation.country;
        location1.properties.title = "records.fields.origin";
        allFeatures.features.push(location1);
        location2.properties.name =
          this.recordSelected.record.sdi_destination.primaryLocation.name;
        location2.properties.address1 =
          this.recordSelected.record.sdi_destination.primaryLocation.address1;
        location2.properties.city =
          this.recordSelected.record.sdi_destination.primaryLocation.city;
        location2.properties.state =
          this.recordSelected.record.sdi_destination.primaryLocation.state;
        location2.properties.country =
          this.recordSelected.record.sdi_destination.primaryLocation.country;
        location2.properties.title = "records.fields.destination";
        allFeatures.features.push(location2);
      }
      if (allFeatures && allFeatures.features.length > 0) {
        this.$refs.mapLocations.updateSource(
          "TrackingSourceAdmin",
          allFeatures
        );
        this.$refs.mapLocations.updateSource("RouteAdmin", this.line);
        this.$refs.mapLocations.onMouseClickSelectTrackingLayer("points");
        this.$refs.mapLocations.setMapZoom(11);
        this.$refs.mapLocations.zoomToFitBounds(allFeatures, 100);
        this.$refs.mapLocations.setMapZoom(16);
      }
    },
    openSheetTrack([properties]) {
      this.$emit("openSheet", ["MapTracking", null, null, properties]);
    },
    /**
     * @return {boolean}
     */
    IsJsonString(json) {
      try {
        JSON.parse(json);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
</style>
